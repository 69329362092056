import styled, { keyframes } from "styled-components";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import Img from "gatsby-image";

const phoneBounce = keyframes`
0% {
  transform: translateY(0);
}
50% {
  transform:  translateY(5px);
}
100% {
  transform:  translateY(0);
}
`;

const cardBounce = keyframes`
0% {
  transform: translateY(0);
}
50% {
  transform: translateY(5px);
}
100% {
  transform: translateY(0);
}
`;

const StyledBackgroundSection = styled.div`
  background: linear-gradient(110.57deg, rgba(228, 255, 248, 0.1) -2.17%, #e4fff8 99.02%);
  position: relative;
  width: 100%;
  z-index: 4;
`;

const HeaderWrapper = styled.div`
  position: relative;
`;

const HeaderTextGroup = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.header};
  display: flex;
  flex-direction: column;
  z-index: 2;

  ${({ theme }) => theme.mq.lg`
    align-items: flex-start;
    flex-basis: 51%;
    padding: 0 50px 0 0;
  `}
`;

const DownloadButtonWrapper = styled.div`
  display: flex;
  margin: 0 auto;

  ${({ theme }) => theme.mq.lg`
  justify-self: flex-start;
    margin: 0;
  `}
`;

const Flex = styled.div`
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 180px;

  ${({ theme }) => theme.mq.lg`
    flex-direction: row;
    padding-top: 210px;
  `}
`;

const ImageWrapper = styled.div`
  flex-basis: 45%;
  margin-top: -20px;
  object-fit: contain;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.mq.sm` 
     flex-basis: 55%;
    margin-top: 20px;
  `}

  ${({ theme }) => theme.mq.md` 
    margin-top: 20px;
  `}

  ${({ theme }) => theme.mq.lg`
    display: flex;
    flex-basis: 55%;
    flex: 1;
    margin-top: -70px;
    height: 450px;
  `}

  ${({ theme }) => theme.mq.xl`
  height: 500px;
    flex-basis: 65%;
  `}
`;

const StyledImageWrapper = styled.div`
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 310px;
  position: relative;
  object-fit:contain;
  width: 80%;
  margin-top: 4.5rem;
 
  img {
      object-fit:contain !important;
      height : 130% !important;

       ${({ theme }) => theme.mq.sm`
          height : 135% !important;
      `}

       ${({ theme }) => theme.mq.md`
          height : 135% !important;
      `}

       ${({ theme }) => theme.mq.lg`
          height : 120% !important;
      `}
  }


  ${({ theme }) => theme.mq.sm`
     max-width: 367px;
    margin-top: -66px;
  `}

  ${({ theme }) => theme.mq.md`
     max-width: 367px;
    margin-top: -65px;
  `}

  ${({ theme }) => theme.mq.lg`
   margin-top: -5px;
  max-width: 100%;
  `}

  ${({ theme }) => theme.mq.xl`
  margin-top: 20px;
    width: 120%;
  `}
`;

const StyledMainImage = styled(Img)`
  width: 100%;
  height: 100%;
`;

const CTA = styled(OutboundLink)`
  svg {
    width: 128px;
    height: 42px;
  }

  ${({ theme }) => theme.mq.md`
  svg {
    width: 179px;
    height: 60px;
  }
  `}

  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }

  &:not(:last-child) {
    margin-right: 16px;
  }
`;

// circle
const StyledCircle = styled(Img)`
  animation: ${phoneBounce} 4s infinite forwards;
  position: absolute !important;
  overflow: visible;
  top: 70px;
    width: 20px;
    left: 190px;
    height: 40px;

  ${({ theme }) => theme.mq.sm`
    top: 140px;
    width: 20px;
    left: 280px;
    height: 40px;
  `}


  ${({ theme }) => theme.mq.md`
     width: 23px;
    top: 140px;
    left: 280px;
    height: 44px;
  `}
  ${({ theme }) => theme.mq.lg`
     width: 20px;
     height: 40px;
    top: 120px;
    left: 290px;
  `}

  ${({ theme }) => theme.mq.xl`
    margin-bottom: 0;
    top: 80px;
    left: 460px;
    width: 20px;
    height: 40px;
  `}
`;

// dotted cirlce
const StyledDot = styled(Img)`
  animation: ${cardBounce} 3s infinite forwards;
  left: 20px;
  position: absolute !important;
  top: 230px;
  width: 50px;
  height: 70px;


   ${({ theme }) => theme.mq.sm`
    top: 330px;
  width: 50px;
  height: 80px;
  `}


  ${({ theme }) => theme.mq.md`
   left: -10px;
    top: 330px;
    height: 80px;
  `}

  ${({ theme }) => theme.mq.lg`
    top: 140px
    left: 0px;
    width: 50px;
    height: 70px;
  `}

  ${({ theme }) => theme.mq.xl`
    margin-bottom: 0;
    left: 120px;
    top: 440px;
    width: 50px;
    height: 70px;
  `}

`;

export {
  StyledCircle,
  StyledDot,
  StyledBackgroundSection,
  HeaderWrapper,
  HeaderTextGroup,
  DownloadButtonWrapper,
  Flex,
  CTA,
  StyledMainImage,
  ImageWrapper,
  StyledImageWrapper,
};
