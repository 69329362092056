import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Fade from "react-reveal/Fade";
import { Container } from "../../../global";
import HeroSection from "../../hero";
import {
  StyledBackgroundSection,
  HeaderWrapper,
  HeaderTextGroup,
  Flex,
  StyledCircle,
  StyledDot,
  ImageWrapper,
  StyledImageWrapper,
  StyledMainImage,
} from "./style";

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      headerImage: file(sourceInstanceName: { eq: "product" }, name: { eq: "join-us-header" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      dottedCircle: file(sourceInstanceName: { eq: "product" }, name: { eq: "dotted-circle" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      circle: file(sourceInstanceName: { eq: "product" }, name: { eq: "circle" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <StyledBackgroundSection>
      <HeaderWrapper id="top">
        <Container>
          <Flex>
            <HeaderTextGroup>
              <Fade bottom delay={250}>
                <HeroSection.Title>Join Us</HeroSection.Title>
              </Fade>

              <Fade bottom delay={500}>
                <HeroSection.Subtitle>
                  Join OPay and help shape the future of payments <br /> for the next billion users. All our vacancies
                  are <br /> advertised on our LinkedIn Page! So keep checking it out!
                </HeroSection.Subtitle>
              </Fade>
            </HeaderTextGroup>
            <ImageWrapper>
              <StyledImageWrapper>
                <StyledMainImage objectFit="contain" fluid={data.headerImage.childImageSharp.fluid} width="100%" />
                <StyledDot objectFit="contain" fluid={data.dottedCircle.childImageSharp.fluid} width="100%" />
                <StyledCircle objectFit="contain" fluid={data.circle.childImageSharp.fluid} width="100%" />
              </StyledImageWrapper>
            </ImageWrapper>
          </Flex>
        </Container>
      </HeaderWrapper>
    </StyledBackgroundSection>
  );
};

export default Header;
