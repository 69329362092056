import React from "react";
import { Box, Heading, Text } from "rebass/styled-components";
import { Container } from "../../global";
import CareersCard from "./card";

const details = [
  {
    title: "Product Head",
    subtitle: "Location: Lahore",
    description: "Full-time",
  },
  {
    title: "Finance Manager",
    subtitle: "Location: Lahore",
    description: "Full-time",
  },
  {
    title: "Payments Gateway Manager",
    subtitle: "Location: Karachi",
    description: "Full-time",
  },
];

const JobOpenings = () => {
  return (
    <Container>
      <Box mb="30px">
        <Box maxWidth="54rem" mx="auto" mb="10rem" mt="15rem">
          <Heading textAlign="center" as="h3" lineHeight="2" mb="0.5rem" mt="50px" fontSize="40px">
            Open Positions
          </Heading>
          <Text textAlign="center" as="p" lineHeight="2" color="header">
            If you are willing to engage on an exciting journey and discover your true potential, apply and become a
            valuable member of the OPay Pakistan team! You will get the opportunity to work for an organization that
            allows you to achieve your career ambitions in diverse domains!
          </Text>
        </Box>
        {details.map((detail, index) => (
          <CareersCard {...detail} key={index} />
        ))}
      </Box>
    </Container>
  );
};

export default JobOpenings;
