import React from "react";
import PropTypes from "prop-types";
import { Text } from "rebass/styled-components";
import { Wrapper, TextGroup, ContentWrapper, StyledButton } from "./style";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const CareerCard = ({ title, description, subtitle }) => {
  return (
    <Wrapper>
      <ContentWrapper>
        <TextGroup>
          <Text mb="4rem" textAlign="left" fontSize="30px" as="h4">
            {title}
          </Text>
          {description ? (
            <Text variant="body" as="p" textAlign="left" mb="4rem">
              {description}
            </Text>
          ) : null}
          <Text variant="body" as="p" textAlign="left" mb="4rem" color="rgba(44, 62, 80, 0.7)">
            {subtitle}
          </Text>
        </TextGroup>
        <StyledButton>
          <OutboundLink
            href="https://www.linkedin.com/company/opay-pk/mycompany/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            Apply Now!
          </OutboundLink>
        </StyledButton>
      </ContentWrapper>
    </Wrapper>
  );
};

export default CareerCard;

CareerCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  subtitle: PropTypes.string,
};
