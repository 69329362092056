import React from "react";
import Layout from "../../components/common/layout/layout";
import SEO from "../../components/common/layout/pkseo";
import Navigation from "../../components/common/navigation/navigation";

import Header from "../../components/sections/careers/header";
import Footer from "../../components/sections/footer";
import JobOpenings from "../../components/sections/careers";

const Careers = () => {
  return (
    <Layout>
      <SEO title="OPay Careers" />
      <Navigation background="white" />
      <Header />
      <JobOpenings />
      <Footer />
    </Layout>
  );
};

export default Careers;
