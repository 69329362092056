import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  box-shadow: 50px 30px 50px 50px rgba(0, 0, 0, 0.05);
  background-color: ${({ theme }) => theme.colors.background.white};
  padding: 20px 30px;
  margin-bottom: 45px;

  ${({ theme }) => theme.mq.lg`
        max-width: 1120x;
        height: 330px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        padding: 0 50px;
        margin: 170px 0;
    `}
`;

export const TextGroup = styled.div`
  width: 100%;

  ${({ theme }) => theme.mq.lg`
        display: flex;
        flex-direction: column;
        padding: 10px;
        width: 60%;
    `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => theme.mq.lg`
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
    `};
`;

export const StyledButton = styled.div`
  width: 170px;
  height: 55px;
  background-color: #1dcf9f;
  border-radius: 100px;
  color: #fff;
  box-shadow: 0px 10px 30px rgba(31, 198, 157, 0.2);
  cursor: pointer;
  transition: all 0.2s ease 0s;
  display: flex;
  justify-content: center;
  align-items: center;
`;
